$(document).ready(function () {
	 
    // Attach the event listener to the language switcher
    const languageSwitcher = document.getElementById('language-switcher');
    if (languageSwitcher) {
        languageSwitcher.addEventListener('click', switchLanguage);
    }
    // Get the language element
    let switch_language = document.querySelector('#switchLanguage');
    // Retrieve the current language from localStorage or default to 'en'
    let current_language = localStorage.getItem('preferredLanguage') || 'en';
    // Set the language display text to "English" or "عربي"
    switch_language.textContent = current_language === 'ar' ? 'عربي' : 'English';
});

function switchLanguage(event) {
    event.preventDefault(); // Prevent default anchor behavior

    // Get the current language from the user's session
    const currentLanguage = frappe.boot.user.language || 'en'; // Default to 'en' if not set
    const newLanguage = currentLanguage === 'ar' ? 'en' : 'ar'; // Toggle between 'ar' and 'en'
    // console.log(frappe.boot.user);

    // Call the Frappe API to update the user's language preference
    frappe.call({
        method: 'frappe.client.set_value',
        args: {
            doctype: 'User',
            name: frappe.session.user, // Current logged-in user
            fieldname: 'language',
            value: newLanguage
        },
        callback: function(response) {
            if (!response.exc) {
                $("#current_language_navbar").text(frappe.boot.user.language === "ar" ? "عربي" : "English");
                localStorage.setItem('preferredLanguage', newLanguage);
                // Reload the page to apply the new language
                location.reload();
            } else {
                // Handle errors (optional)
                frappe.msgprint(__('Failed to switch language. Please try again.'));
            }
        }
    });
}